.container {
  border-top: 0.5px solid var(--light-grey-color);
  display: flex;
  overflow: hidden;
}

.form-container {
  width: 100%;
  padding: 20px 0;
  overflow: auto;
}

.revisions-history {
  border-left: 0.5px solid var(--light-grey-color);
  width: 284px;
  overflow: auto;
}