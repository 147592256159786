.card {
  min-width: 400px;
  max-width: 400px;
  box-shadow: 0 0 20px #00000029;
  background-color: var(--white);
  border-radius: 6px;
  border-bottom: 7px solid var(--yellow-color);
  width: 33.33%;
  cursor: pointer;
  flex-wrap: wrap;
  min-height: 400px;

  img {
    cursor: pointer;
  }

  &__header {
    width: 100%;
    padding: 9px 20px;
    border-bottom: 1px solid var(--light-grey-color);

    &__title {
      font-weight: bold;
      color: var(--text-color);
      font-size: 22px;
    }

    &__subtitle {
      font-family: AvenirNext-Regular, AvenirNext-Demi, sans-serif;
      font-size: 12px;
    }
  }

  &__body {
    padding: 34px 20px 20px 20px;

    &__box {
      div {
        font-family: AvenirNext-Regular, AvenirNext-Demi, sans-serif;
        font-size: 12px;
        margin-bottom: 17px;
      }

      &__type-initial {
        display: -webkit-box !important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    &__box {
      div:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__footer {
    padding: 0 20px 20px 20px;
    img {
      margin-right: 10px;
    }

    img:last-child{
      margin-right: 0;
    }
  }
}

@media (max-width: 450px) {
  .card {
    min-width: 280px;
    width: 90vw;
    max-width: 90vw;
  }
}