.container {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;
	//width: 100%;
}

.item {
	max-width: 300px;
	min-width: 233px;
	min-height: 221px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-shadow: 0 0 20px #00000029;
	width: 30%;
	margin: 15px;
	//transition: 0.5s ease-in-out;
	border-bottom: 7px solid var(--yellow-color);
	border-top: 0.5px solid transparent;
	border-left: 0.5px solid transparent;
	border-right: 0.5px solid transparent;
	border-radius: 6px;
	background-color: white;

	&:hover{
		cursor: pointer;
		border-color: var(--yellow-color);

		.image{
			img{
				filter: invert(65%) sepia(48%) saturate(713%) hue-rotate(358deg) brightness(95%) contrast(89%);
			}
		}
	}
}

.document {
	@extend .item;
	min-height: 121px;
	width: 30vw;
	&:hover{
		cursor: auto;
	}
}

.enum {
	@extend .item;
	min-height: 60px;
	max-height: 60px;
	width: calc(30vw - 30px);
	max-width: calc(30vw - 30px);
	border-bottom: 0.5px solid transparent;
}

.image {
	height: 83px;
	width: 90%;
	margin: 30px auto 8px;

	img{
		transition: 0.5s ease-in-out;
		width: 100%;
		height: 100%;
		object-fit: contain;
		filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(358deg) brightness(100%) contrast(100%);
	}
}

.title {
	font-family: "AvenirNext-Demi", sans-serif;
	font-size: 18px;
	width: 80%;
	margin: 8px auto 22px;
	transition: 0.3s ease-in-out;
	text-align: center;
}

.titleDocument {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: "AvenirNext-Demi", sans-serif;
	font-size: 18px;
	width: 90%;
	margin: 12px auto 12px;
	transition: 0.3s ease-in-out;
	overflow-wrap: anywhere;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.titleEnum {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-family: "AvenirNext-Demi", sans-serif;
	font-size: 16px;
	width: 90%;
	margin: auto;
	padding: 0 5px;
	.titleLimit {
		width: 75%;
		overflow-wrap: anywhere;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}

.explanationContainer {
	border-top: 0.5px solid var(--light-grey-color);
	height: 150px;
}

.explanation {
	padding: 16px;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.iconContainer {
	@extend .explanation;
	margin-top: 20px;
	justify-content: end;
	img {
		cursor: pointer;
	}
}

.explanationDocument {
	padding: 16px;
	height: 42px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.explanationTitle {
		color: var(--text-color);
	}
	.explanationDescriptionValue {
		overflow-wrap: anywhere;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}

.explanationDescription {
	@extend .explanationDocument;
	min-height: 42px;
	height: auto;
}

@media (max-width: 310px) {
	.item {
		min-width: 185px;
	}
}