@import "../../assets/style/variables";

.button {
  font-family: AvenirNext-Demi, sans-serif;
  font-size: 14px;
  border-style: none;
  width: auto;
  border-radius: var(--button-border-radius);
  padding: 7px 30px;
  margin: 3px 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition);
  height: 36px;

  &--thin_button {
    padding: 3px 7px;
    margin: 0;
    height: auto;
  }

  &:focus {
    outline: none;
  }
}

.save,
.edit {
  background-color: var(--secondary-color);
  transition: var(--transition);
  color: var(--white-color);

  &:hover {
    background-color: var(--button-hover-color);
    color: var(--white-color);
  }

  &:disabled {
    background-color: var(--disabled-color);
    color: var(--white-color);
  }
}

.save-white {
  background-color: var(--white-color);
  transition: var(--transition);
  color: var(--black-color);
  border: 1px solid var(--disabled-color);

  &:hover, &:focus {
    background-color: var(--blue-hover-color);
    color: var(--white-color);
    border: 1px solid var(--disabled-color);
  }

  &:disabled {
    background-color: var(--disabled-color);
    color: var(--white-color);
  }
}

.save-grey {
  background-color: var(--light-grey-color);
  transition: var(--transition);
  color: var(--gray-dark);
  border: none;
  height: 28px;

  &:hover, &:focus {
    background-color: #9CFAB1;
  }
}

.cancel {
  background-color: transparent;
  transition: var(--transition);
  color: var(--light-text-color);
  text-transform: uppercase;

  &:hover {
    color: var(--light-text-hover-color);
  }

  &:disabled {
    color: var(--text-color);
  }
}
.delete {
  background-color: var(--error-color);
  transition: var(--transition);
  color: var(--white-color);

  &:hover {
    background-color: var(--error-hover-color);
  }

  &:disabled {
    background-color: var(--error-disabled-color);
  }
}