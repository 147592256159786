.pending, .approved, .denied {
  font-family: AvenirNext, AvenirNext-Bold, sans-serif;
  font-size: 12px;
  padding: 5px 9px;
  border-radius: 5px;
  margin-right: 5px;
}

.pending {
  color: var(--yellow-color);
  border: 1px solid var(--yellow-color);
}

.approved {
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.denied {
  color: var(--red-color);
  border: 1px solid var(--red-color);
}

.insertExplanation {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid;
}