@import '../../../assets/style/variables';

#livestockTable {

  .ant-table-thead tr th:nth-child(2) {
    @media (max-width: $md) {
      background-color: var(--yellow-color);
    }
  }


  @media screen and (min-width: $md) {
    .ant-table-thead tr th:nth-child(2), .ant-table-thead tr th:nth-child(3), .ant-table-thead tr th:nth-child(4) {
      background-color: var(--yellow-color);
    }
  }

  @media screen and (min-width: $lg) {
    .ant-table-thead tr th:nth-child(2), .ant-table-thead tr th:nth-child(3) {
      background-color: transparent;
    }

    .ant-table-thead tr th:nth-child(4), .ant-table-thead tr th:nth-child(5), .ant-table-thead > tr > th:nth-child(12n) {
      background-color: var(--yellow-color);
    }
  }

  .ant-table-tbody > tr > td:first-child {
    @media (min-width: 1500px) {
      width: 10%;
    }
  }
}

.ant-table-thead > tr > th div, .ant-table-tbody > tr > td {
  @media (max-width: 1500px) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: keep-all;
  }
}
