.swal2-title {
  font-family: 'AvenirNext-Demi', sans-serif;
  color: var(--text-color);
  font-size: 16px;
}

.swal2-close {
  margin-top: 0.7rem !important;
  padding-right: 0.8rem !important;
}

.swal2-cancel {
  &:focus {
    outline: none;
  }
  border: none;
  background-color: transparent;
  transition: var(--transition);
  color: var(--light-text-color);
  text-transform: uppercase;

  &:hover {
    color: var(--light-text-hover-color);
  }

  &:disabled {
    color: var(--text-color);
  }
}
.swal2-popup {
  background-color: var(--modal-background-color);
  border: var(--modal-borders);
}
.swal2-confirm {
  background-color: var(--error-color);
  transition: var(--transition);
  color: var(--white-color);
  border: none;
  border-radius: var(--button-border-radius);
  &:hover {
    background-color: var(--error-hover-color);
  }

  &:disabled {
    background-color: var(--error-disabled-color);
  }
}

.swal2-header {
  &.border {
    border-bottom: var(--modal-borders);
  }
}

.modal-footer {
  border-bottom: var(--modal-borders);
}

.swal2-content{
  font-family: "AvenirNext-Regular", sans-serif;
  font-size: var(--field-font-size);
  color: var(--text-color);
}