.container{
  position: relative;
}

.input-field {
  width: 100%;
  padding: 12px 40px 12px 12px;
  min-height: var(--min-field-height);
  font-size: var(--field-font-size);
  color: var(--text-color);
  font-family: 'AvenirNext-Demi', sans-serif;
  background-color: white;
  border: 0.5px solid var(--field-border-color);
  border-radius: var(--field-border-radius);
  transition: var(--transition);

  &:focus {
    box-shadow: 0 0 0 0.5px var(--secondary-color);
    border-color: var(--secondary-color);
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--placeholder-color) !important;
    font-family: 'AvenirNext-Light', sans-serif;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--placeholder-color) !important;
    font-family: 'AvenirNext-Light', sans-serif;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--placeholder-color) !important;
    font-family: 'AvenirNext-Light', sans-serif;
  }
}

.disabled {
  border-color: var(--field-disabled-color);
  background-color: var(--field-disabled-color);
}

.error {
  border-color: var(--error-color);
  box-shadow: 0 0 0 0.2px var(--error-color) inset;

  &:focus {
    border-color: var(--error-color);
    box-shadow: 0 0 0 1px var(--error-color) inset;
  }
}

.eyeIcon {
  position: absolute;
  top: 30%;
  //transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
  color: #00000080 !important;
  font-size: 18px;
}
