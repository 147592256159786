.container{
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
}

.item{
	max-width: 300px;
	min-width: 233px;
	min-height: 265px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-shadow: 0 0 20px #00000029;
	width: 30%;
	margin: 15px;
	transition: 0.5s ease-in-out;
	border-bottom: 7px solid var(--secondary-color);
	border-top: 0.5px solid transparent;
	border-left: 0.5px solid transparent;
	border-right: 0.5px solid transparent;
	border-radius: 6px;
	background-color: white;

	&:hover{
		cursor: pointer;
		border-color: var(--secondary-color);

		.image{
			img{
				filter: invert(67%) sepia(8%) saturate(2179%) hue-rotate(81deg) brightness(82%) contrast(56%);
			}
		}
	}
}

.image{
	height: 83px;
	width: 90%;
	margin: 30px auto 8px;

	img{
		transition: 0.5s ease-in-out;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.title{
	font-family: "AvenirNext-Demi", sans-serif;
	font-size: 18px;
	width: 80%;
	margin: 8px auto 22px;
	transition: 0.3s ease-in-out;
	text-align: center;
}

.explanation{
	padding: 16px;
	height: 52px;
	border-top: 0.5px solid var(--light-grey-color);
	display: flex;
	align-items: center;
	justify-content: space-between;
}