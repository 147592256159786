.select-field-general {
  div.css-yk16xz-control,
  div.css-1fhf3k1-control {
    border-color: var(--field-border-color);
    border-radius: var(--field-border-radius);
    min-height: 48px;
  }

  div.css-yk16xz-control,
  div.css-1fhf3k1-control,
  div.css-1hwfws3,
  div.css-1pahdxg-control {
    min-height: var(--min-field-height);
  }

  div.css-1uccc91-singleValue,
  div.css-1rhbuit-multiValue,
  div.css-1fhf3k1-control {
    color: var(--text-color);
    font-family: 'AvenirNext-Demi', sans-serif;
    font-size: var(--field-font-size);
  }

  div.css-1wa3eu0-placeholder {
    font-family: 'AvenirNext-Light', sans-serif;
    color: var(--placeholder-color);
    font-size: var(--field-font-size);
  }

  .css-4ljt47-MenuList,
  .css-6x9qa6-MenuList,
  .css-2613qy-menu,
  .css-11unzgr {
    font-size: 13px;
    color: var(--text-color);
    font-family: 'AvenirNext-Regular', sans-serif;
    text-align: left;
  }

  div.css-1pahdxg-control {
    border-color: var(--secondary-color);
    outline: none;
    box-shadow: 0 0 0 0.5px var(--secondary-color);

    &:hover {
      box-shadow: 0 0 0 0.5px var(--secondary-color);
      border-color: var(--secondary-color);
    }
  }

  .css-g1d714-ValueContainer {
    font-family: 'AvenirNext-Demi', sans-serif;
    font-size: var(--field-font-size);
  }

  div.css-b8ldur-Input {
    input {
      border-color: transparent !important;
      outline: none;
      box-shadow: none !important;
    }
  }

  .css-tlfecz-indicatorContainer {
    path {
      color: var(--field-border-color);
    }
  }

  .css-1okebmr-indicatorSeparator {
    background-color: var(--field-border-color);
  }

  div.css-1pahdxg-control {
    .css-1gtu0rj-indicatorContainer {
      transform: rotate(180deg);
    }
  }

  .css-1g6gooi {
    input:not([type='checkbox']):focus {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }

  .info {
    .avatar-user {
      display: inline-flex;
      margin-right: 10px;
    }
  }

  &.disabled {
    pointer-events: none;

    .css-yk16xz-control,
    .css-1fhf3k1-control {
      border: 0.5px solid var(--field-disabled-color);
      background-color: var(--field-disabled-color);
    }

    .css-1hb7zxy-IndicatorsContainer,
    .css-1wy0on6 {
      visibility: hidden;
    }

    .css-xb97g8 {
      display: none;
    }

    div.css-1fhf3k1-control .css-107lb6w-singleValue {
      color: var(--text-color) !important;
    }
  }

  &.error {
    .css-yk16xz-control,
    .css-1fhf3k1-control {
      border-color: var(--error-color);
      box-shadow: 0 0 0 0.2px var(--error-color) inset;
    }

    div.css-1pahdxg-control {
      border-color: var(--error-color);
      outline: none;
      box-shadow: 0 0 0 1px var(--error-color) inset;
    }
  }
}
