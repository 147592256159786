.search-field {
  width: 100%;
  min-width: 250px;
  min-height: 48px;
  padding: 12px;
  font-size: var(--field-font-size);
  color: var(--text-color);
  font-family: 'AvenirNext-Regular', sans-serif;
  background-color: white;
  border: 0.5px solid var(--field-border-color);
  border-radius: var(--field-border-radius);
  transition: var(--transition);

  &:focus {
    box-shadow: 0 0 0 0.5px var(--doc-blue-color);
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--placeholder-color) !important;
    font-family: 'AvenirNext-Light', sans-serif;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--placeholder-color) !important;
    font-family: 'AvenirNext-Light', sans-serif;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--placeholder-color) !important;
    font-family: 'AvenirNext-Light', sans-serif;
  }
}

@media screen and (max-width: 620px) {
  .search-field {
    margin: 0 auto;
  }
}

.prefix {
  border: none;
  width: calc(100% - 30px);
}
