@import '../../../assets/style/variables';

#explainableControlsTable {
    .ant-table-thead > tr > th div, .ant-table-tbody > tr > td span {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
    }

    .ant-table-thead > tr > th div, .ant-table-tbody > tr > td {
        white-space: normal;
    }
}
