.icon {
  color: var(--primary-color);
  font-size: 14px;
  margin-left: 5px;
}

.tooltip {
  font-family: 'AvenirNext-Regular', sans-serif;
  font-size: var(--label-font-size);
  margin-bottom: 2px;
  cursor: pointer;
}