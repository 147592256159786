.dropdown {
  margin-right: 16px;

  &__menu {
    margin-top: 9px;
    padding-top: 20px;
    width: 100vw !important;
    height: 100vh !important;
    border-radius: 0 !important;
    //box-shadow: 0 3px 12px #00000029 !important;
    &--item {
      font-size: 18px;
      padding: 18px 36px !important;
      &:hover {
        background-color: transparent !important;
      }
      span {
        a {
          padding: 8px 12px;
          border-radius: 4px;
          &:hover {
            background-color: var(--light-grey-color);
            text-decoration: none;
          }
        }
      }
    }
  }
}