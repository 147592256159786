.modal-wrapper-custom {
  .modal-content {
    background-color: var(--modal-background-color);
    border: var(--modal-borders);
  }

  .modal-header {
    border-bottom: var(--modal-borders);
    font-family: 'AvenirNext-Demi', sans-serif;
    color: var(--text-color);
    font-size: 16px;
    padding: 20px 25px;
    .close {
    }
  }

  .modal-body {
    padding: 20px 25px;
  }
}

.modalLg {
  .modal-dialog {
    min-width: 520px !important;
  }
}

.modalXl {
  .modal-dialog {
    min-width: 820px !important;
  }
}

.modalXl-responsive {
  .modal-dialog {
    min-width: 1500px !important;
  }

  @media screen and (max-width: 1500px) {
    .modal-dialog {
      min-width: 90vw !important;
    }
  }
}

// used for keeping the modal in the middle part of second screen half
.modal-shift-right {
  .modal-dialog {
    position: absolute;
    width: 30vw;
    right: 10%;
  }
}
