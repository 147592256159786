.content {
  height: calc(100vh - 63px);
  overflow-y: auto;
  overflow-x: hidden;

  &--title {
    font-size: 18px;
    color: var(--dark-text-color);
    font-family: AvenirNext-Bold, AvenirNext-Demi, sans-serif;
    margin-bottom: 28px;
  }

  &--card {
    gap: 30px;
  }
}

.placeholder {
  height: 60vh;
  color: var(--placeholder-color);
  font-size: 16px;
}
.link {
  color: var(--secondary-color);
  cursor: pointer;
}

.button {
  color: var(--dark-text-color);
  background-color: transparent;
  border-radius: 4px !important;
  border-width: 2px !important;
  border-color: black !important;
  padding: 7px 13px !important;
  height: auto !important;
  margin-bottom: 48px;
  min-width: 130px;
  max-width: 150px;

  &:hover {
    box-shadow: 0 0 8px rgba(33,33,33,.2);
  }
}