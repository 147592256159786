.contentWrapper {
  overflow: hidden;

  &__background {
    background-color: #08964d;
    background-image: url("../../../assets/images/montenegro.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &__actions {
    position: absolute;
    right: 30px;
    top: 30px;
  }

  &__logo {
    padding-bottom: 50px;
  }

  &__welcome {
    font-family: AvenirNext-Regular, AvenirNext, sans-serif;
    font-size: 15px;
    color: var(--dark-text-color);
    padding-bottom: 50px;
    width: 75%;
    text-align: center;
  }
}