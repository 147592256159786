.contentWrapper {
  overflow: hidden;
  overflow-y: auto;

  &__background {
    background-color: #08964d;
    background-image: url("../../../assets/images/montenegro.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &__logo {
    padding-bottom: 50px;
  }

  &__welcome {
    font-family: AvenirNext-Regular, AvenirNext, sans-serif;
    font-size: 15px;
    color: var(--dark-text-color);
    padding-bottom: 50px;
    max-width: 90vw;
  }

  &__form {
    text-align: center;

    &--forgot {
      display: flex;
      justify-content: center;
      font-family: AvenirNext-Regular, AvenirNext, sans-serif;
      font-size: 12px;
      color: var(--text-color);
      padding: 26px 0 60px 0;
      text-decoration: underline;
      span:hover {
        cursor: pointer;
      }
    }
  }

  &__register {
    display: inline-block;
    padding-top: 60px;
    padding-bottom: 30px;
    font-family: AvenirNext-Regular, AvenirNext, sans-serif;
    font-size: 15px;
    color: var(--dark-text-color);

    &--link {
      font-family: AvenirNext-Bold, AvenirNext-Demi, sans-serif;
      color: var(--secondary-color);
      cursor: pointer;
    }
  }
}