.sidebar {
  height: 100%;
  position: absolute;
  overflow-x: hidden;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 3;
  background-color: var(--white);
  border-right: 0.5px solid var(--light-grey-color);

  button {
    border: none;
    background-color: transparent;
  }
}

.sidebar-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  width: 100%;
  height: 70px;
  text-align: center;
  z-index: 3;
  background-color: var(--white);
  border-bottom: 0.5px solid var(--light-grey-color);
}

.sidebar .links {
  transition: 0.3s ease-in-out;
  border-right: 0.5px solid var(--light-grey-color);
}

.sidebar .links a,
.sidebar .links button {
  transition: 0.3s ease-in-out;

  padding: 15px 0;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .tooltip-text {
    position: relative;
    text-transform: uppercase;
    font-family: AvenirNext-Demi, AvenirNext-Regular, sans-serif;
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px;
    color: var(--text-color);
    max-width: 110px;
    word-break: break-word;
  }
  border-right: none;

  img {
    filter: invert(.5);
  }
}

.sidebar .links.active {
  border-right: 3px solid var(--red-color);
  a, button {
    .tooltip-text {
      color: var(--dark-text-color);
    }

    img {
      filter: none;
    }
  }
}

.sidebar .linksExtra {
  border-right: 3px solid var(--light);
  a, button {
    background-color: var(--light);
  }
}

@media (max-width: 768px) {
  .sidebar {
    border-right: none;
    height: fit-content;
    width: 100%;
    display: flex;
  }

  .sidebar .filler {
    height: 0;
  }

  .sidebar .links {
    transition: 0.3s ease-in-out;
    border-right: 0.5px solid var(--light-grey-color);
    border-bottom: 0.5px solid var(--light-grey-color);
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .sidebar .links a,
  .sidebar .links button {
    transition: 0.3s ease-in-out;

    padding: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 100px;
    height: 89px;
    border-right: none;
  }

  .sidebar .links.active {
    border-right: 0.5px solid var(--light-grey-color);
    border-bottom: 3px solid var(--red-color);
  }

  .sidebar .linksExtra {
    border-bottom: 3px solid var(--light);
    a, button {
      background-color: var(--light);
    }
  }
}

@media (max-width: 605px) {
  .sidebar {
    top: 70px;
    flex-wrap: wrap;
  }

  .sidebar .links {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 30%;
  }

  .sidebar .linksExtra {
    border-bottom: 0.5px solid var(--light-grey-color);
    a, button {
      background-color: var(--light);
    }
  }
}