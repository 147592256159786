#infinite-container {
  height: 100%;
  width: 100%;
  //overflow: auto;

  div.infinite-scroll-component {
    overflow: hidden !important;

    .ant-table {
      width: 100%;

      .ant-dropdown {
        width: 200px;
      }

      .ant-table-thead {
        overflow-wrap: unset;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        background: var(--white-color);
        font-size: 12px;
        th.ant-table-cell {
          background: var(--white-color);
          border: 0;
          font-family: 'AvenirNext-Demi', sans-serif;
        }
      }

      .ant-table-body {
        font-size: 12px;
        tr {
          td {
            overflow-wrap: unset;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        table {
          border-collapse: separate;
          border-spacing: 0 6px;
          width: 100%;

          tr.ant-table-row {
            background-color: #f8fbff;

            &:hover td {
              border-color: var(--secondary-color);
            }
          }

          .ant-table-cell {
            text-overflow: ellipsis;
            border-top: 1px solid var(--light-blue-color);
            border-bottom: 1px solid var(--light-blue-color);
            vertical-align: baseline;
            background-color: var(--field_background);

            &:first-child {
              border-left: 1px solid var(--light-blue-color);
              border-radius: 6px 0 0 6px;
            }
            &:last-child {
              border-right: 1px solid var(--light-blue-color);
              border-radius: 0 6px 6px 0;
            }

            .ant-form-item {
              margin-bottom: 0;

              .ant-input-number {
                min-width: 90px;
                width: 100%;
              }
            }

            &.actions {
              svg {
                height: 13px;
                width: 13px;
                margin-bottom: 7px;
              }
              :focus {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}
div.users.administration-template {
  overflow: hidden;
}
.app-body {
  overflow: hidden;
}
.table-hover {
  cursor: pointer;
}

.ant-table-row-disabled {
  background-color: var(--light-grey-color);
}
