.title {
  color: #37393C;
  font: normal normal bold 16px/22px;
  align-items: center;
}

.link {
  color: var(--blue-link-color);
}

.back {
  font-size: 0.9em;
  margin-top: -20px;
}