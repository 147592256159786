.error-page-container {
  width: 100vw;
  height: 100vh;
  transition: 0.6s ease-in-out;
  opacity: 1;

  &.hide {
    opacity: 0;
  }

  .info-containter {
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - 0.5px), calc(-50% - 0.5px));

    .errorCode {
      img {
        height: 100%;
        width: 100%;
      }
    }

    .message-text {
      text-align: center;

      span {
        font-family: 'AvenirNextCyr-Regular', sans-serif;
        color: var(--text-color);
        font-size: 20px;
        line-height: 31px;
        padding-top: 10px;
        display: inline-block;
      }
    }

    .return-text {
      padding-top: 40px;
      text-align: center;
      transition: 0.3s ease-in-out;

      button {
        font-family: 'AvenirNext-Demi', sans-serif;
        border: none;
        width: 360px;
        height: 45px;
        border-radius: 4px;
        background-color: var(--secondary-color);
        transition: 0.3s ease-in-out;
        color: white;
        text-transform: uppercase;
        letter-spacing: 1px;

        &:hover {
          cursor: pointer;
          background-color: var(--secondary-color);
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .error-page-container {
    .info-containter {
      width: 80%;

      .message-text {
        span {
          font-size: 15px;
        }
      }
    }
  }
}
