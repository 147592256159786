.button {
  width: 90% !important;
  color: var(--dark-text-color) !important;
  background-color: var(--white) !important;
  border: 0.5px solid var(--light-grey-color) !important;
  padding: 9px 15px !important;
  height: auto !important;
  &:hover {
    border-color: black !important;
  }
}