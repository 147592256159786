.status {
  border-radius: var(--field-border-radius);
  padding: 3px;
  font-size: 13px;

  &--complete {
    color: var(--secondary-color);
    border: 2px solid var(--secondary-color);
  }

  &--on_hold {
    color: var(--yellow-color);
    border: 2px solid var(--yellow-color);
  }
}