// Global styles

// Ant Design Stepper
.steps-content {
  border: 1px dashed var(--disabled-color);
  border-radius: 2px;
  min-height: 200px;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.steps-action {
  margin-top: 20px;
}

// Center stepper icon
svg {
  vertical-align: baseline;
}

.ant-steps-item-title {
  font-family: AvenirNext-Demi, sans-serif !important;
  font-size: 14px !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: flex;
}
// End Ant Design Stepper

ul {
  list-style-type: none;
}

// Text style
.text-bold {
  font-family: 'AvenirNext-Bold', sans-serif;
  font-weight: 700;
}

// Link style
.link {
  cursor: pointer;
  color: var(--blue-link-color);
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link-document {
  border: solid 1px #eaeaea;
  border-radius: 5px;
  cursor: pointer;
  color: var(--primary-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  padding: 5px 7px;
  margin-right: 10px;
  margin-top: 10px;
  font-family: 'AvenirNext-Bold', sans-serif;
}

.show-more {
  text-decoration: underline;
  color: var(--primary-color);
  line-height: 2.58;
  letter-spacing: normal;
  text-align: left;
  display: flex;
  align-items: center;
  font-family: 'AvenirNext-Regular', sans-serif;
  font-weight: 100;
  &:hover{
    text-decoration: underline;
  }
}

.transition {
  transition: var(--transition);
}

.cursor {
  cursor: pointer;
}

.mw-250 {
  max-width: 250px;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

// VALIDATIN ERRORS STYLE

.validationError {
  background-color: #E5001910;
  color: var(--red-color);
  padding: 10px;
  border-radius: 5px;
}

.warningError {
  background-color: var(--yellow-color-light);
  color: var(--white);
  padding: 10px;
  border-radius: 5px;
}

.validationExplain, .validationExplainView {
  background-color: #E5001910;
  color: var(--red-color);
  padding: 10px;
  border-radius: 5px;
  margin-top: 8px;

  .explanation {
    background-color: var(--white);
    color: var(--dark-text-color);
    border-radius: 5px;
    padding: 5px 10px;
    flex-wrap: wrap;
  }
}

.validationExplainView {
  background-color: rgba(103, 178, 120, 0.7);
  color: var(--white);
}


// Remove arrows from number input fields
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}

.tableLink {
  color: #001fba;

  &:hover {
    color: #001fba;
  }
}

.grayText {
  color: var(--light-text-color);
}

.ant-dropdown.ant-dropdown-placement-bottomRight,
.ant-dropdown.ant-dropdown-placement-bottomLeft {
  overflow: hidden !important;
  top: 54px !important;
}
