.dropdown {
  margin-right: 16px;
  &__button {
    color: var(--dark-text-color) !important;
    background-color: var(--light-grey-color) !important;
    border-radius: 5px !important;
    border-width: 2px !important;
    border-color: transparent !important;
    padding: 7px 13px !important;
    height: auto !important;
    &:hover {
      border-color: black !important;
    }
  }

  &__menu {
    border-radius: 5px !important;
    box-shadow: 0 3px 12px #00000029 !important;
    &--item {
      padding: 10px 0 !important;
    }
  }
}