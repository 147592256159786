.App {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: var(--white);
  font-size: 12px;
}

.appContent {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 120.41px);
}

.appContainer {
  padding: 20px 45px 60px 45px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
}

.agroContainer {
  position: relative;
  background-color: white;
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 60px 60px 60px;
  //-webkit-transition: all 500ms ease-in-out;
  //transition: all 500ms ease-in-out;
}

.agroContainer.opened {
  width: calc(100% - 400px);
  margin-left: 400px;
}

.agroContainer.closed {
  width: calc(100% - 153px);
  margin-left: 153px;
}

.sidebarExtra {
  height: 100%;
  min-width: 253px;
  position: fixed;
  top: 120.41px;
  text-align: left;
  background-color: var(--white);
  border-right: 1px solid var(--light-grey-color);

  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  z-index: 2;

}

.sidebarExtra.closed {
  left: -253px;
}

.sidebarExtra.opened {
  left: 153px;
  top: 118px;
  min-width: 250px;
  background-color: var(--light);
}

.sidebarExtra div.links {
  height: 40px;
  width: 100%;
}

.sidebarExtra div.links > a {
  display: block;
  font-family: 'AvenirNext-Medium', AvenirNext-Demi, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  color: var(--dark-text-color);
  text-decoration: none;
  height: 100%;
  width: 100%;
  padding: 10px 25px;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
}

.sidebarExtra div.links > a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

@media (max-width: 768px) {
  .appContainer {
    padding: 20px 10px 40px 20px;
  }

  .agroContainer {
    padding: 120px 20px 60px;
  }

  .agroContainer.opened {
    width: 100%;
    margin-left: 0;
  }

  .agroContainer.closed {
    width: 100%;
    margin-left: 0;
  }

  .sidebarExtra {
    height: fit-content;
    top: 257px;
    border-right: none;
    border-bottom: 1px solid var(--light-grey-color);

    -webkit-transition: none;
    transition: none;
  }

  .sidebarExtra.opened {
    left: 0;
    top: 257px;
    width: 100%;
    height: fit-content;
  }

  .sidebarExtra div.links {
    height: 50px;
    width: 100%;
  }
}

@media (max-width: 605px) {
  .sidebarExtra {
    top: 416px;
  }

  .sidebarExtra.opened {
    top: 416px;
  }

  .agroContainer {
    padding-top: 80px;
  }
}

@media (max-width: 480px) {
  .sidebarExtra {
    top: 369px;
  }

  .sidebarExtra.opened {
    top: 369px;
  }
}