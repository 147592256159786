.button {
  color: var(--dark-text-color);
  background-color: transparent;
  border-radius: 4px !important;
  border-width: 2px !important;
  border-color: black !important;
  padding: 3px 13px !important;
  height: auto !important;
  margin: 3px 4px 48px;
  min-height: 35px;
}