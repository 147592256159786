.contentWrapper {
  overflow: hidden;
  overflow-y: scroll;

  &__background {
    background-color: #08964d;
    background-image: url("../../../assets/images/montenegro.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &__logo {
    padding-bottom: 50px;
  }

  &__welcome {
    font-family: AvenirNext-Regular, AvenirNext, sans-serif;
    font-size: 15px;
    color: var(--dark-text-color);
    padding-bottom: 50px;
  }

  &__form {
    width: 50%;
    .submit {
      margin-top: 50px;
    }
  }
}