.history-item{
	border-bottom: 0.5px solid var(--light-grey-color);
	padding: 18px 21px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	transition: 0.3s ease-in-out;
	
	&:hover{
		background-color: #F5F5F5;
		cursor: pointer;
	}

	&.active{
		background-color: #F5F5F5;
	}

	.status-container{
		display: flex;
		align-items: center;
	}

	.time{
		font-family: "AvenirNext-Bold", sans-serif;
		font-size: 14px;
		color: #141B2C;
	}

	.status{
		font-family: "AvenirNext-Bold", sans-serif;
		font-size: 14px;
		padding-left: 7px;
		min-width: 86.86px;
		&.red{
			color: #E50019;
		}

		&.orange{
			color: #E5A125;
		}

		&.green{
			color: #67B278;
		}
	}

	.user{
		font-family: "AvenirNext-Regular", sans-serif;
		font-size: 12px;
		color: #141B2C;
	}

	@media (max-width: 620px) {
		.status-container {
			flex-wrap: wrap;
		}

		.status {
			padding-left: 0;
		}
	}
}