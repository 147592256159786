.modal-title {
   font-size: 16px !important;
   color: var(--dark-text-color) !important;
   font-family: AvenirNext-Bold, AvenirNext-Demi, sans-serif !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
 }

.modalTitle {
  font-size: 12px;
  color: var(--text-color);
  font-family: AvenirNext-Bold, AvenirNext-Demi, sans-serif;
  margin: 6px;
  word-break: break-word;
}

.explain-modal{
  .close{
    display: none;
  }
}

.chatContainer {
  overflow-y: auto;
  max-height: 140px;
  padding-right: 15px;
}

.dateContainer {
  font-size: 10px;
  color: var(--dark-text-color);
  font-family: AvenirNext-Bold, AvenirNext-Demi, sans-serif;
  padding: 5px 10px;
  border: 1px solid var(--dark-text-color);
  border-radius: 25px;
  max-width: 160px;
  margin: auto;
}

.message {
  padding: 10px;
  margin-bottom: 15px;
  font-family: AvenirNext-Regular, AvenirNext-Demi, sans-serif;
  font-size: 14px;
  max-width: 200px;
  word-break: break-word;
}

.messageTime {
  font-size: 10px;
  margin-bottom: 5px;
}

.myMessage {
  background-color: var(--dark-text-color);
  color: var(--white);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 25px;
  margin-left: auto;
}

.myMessageTime {
  margin-left: auto;
  color: var(--background-grey);
}

.receivedMessage {
  background-color: var(--grey-color);
  color: var(--black-color);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  margin-right: auto;
}

.receivedMessageTime {
  margin-right: auto;
  color: var(--background-grey);
}

.user {
  margin-left: auto;
  font-size: 10px;
  color: var(--black-color);
  font-family: AvenirNext-Bold, AvenirNext-Demi, sans-serif;
}