.dateFnsComponent {
  position: relative;
  transition: var(--transition);
}

.dateFnsComponent.form-field-date {
  width: 100%;
  position: relative;
  border: 0.5px solid var(--field-border-color);
  border-radius: var(--field-border-radius);

  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .input-date {
    border-radius: inherit;
  }

  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      width: 100%;

      .input-date {
        width: 100%;
        border: none;
        font-family: 'AvenirNext-Demi', sans-serif;
        padding: 12px 40px 12px 12px;
        font-size: var(--field-font-size);
        color: var(--text-color);
        min-height: var(--min-field-height);

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: var(--placeholder-color) !important;
          font-family: 'AvenirNext-Light', sans-serif;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: var(--placeholder-color) !important;
          font-family: 'AvenirNext-Light', sans-serif;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: var(--placeholder-color) !important;
          font-family: 'AvenirNext-Light', sans-serif;
        }

        &:focus {
          box-shadow: 0 0 0 1.5px var(--secondary-color);
          border-color: var(--secondary-color);
        }
      }

      .react-datepicker__close-icon {
        margin-right: 25px;
      }
    }
  }

  &.error {
    border-color: var(--error-color);

    input {
      transition: var(--transition);
      box-shadow: 0 0 0 0.2px inset var(--error-color) !important;
    }

    input:focus {
      box-shadow: 0 0 0 1px inset var(--error-color) !important;
    }
  }

  &.disabled {
    background-color: var(--field-disabled-color);
    border: 0.5px solid var(--field-disabled-color);
  }

  .calendar-img-aps {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: auto;
  }
}
