:root{
  --primary-color: #F5F5F5;
  --secondary-color: #67B278;

  --button-hover-color: #367344;
  --background-grey: #454545;

  --yellow-color: #E5A125;
  --yellow-color-light: rgb(229, 161, 37, 0.8);
  --red-color: #E50019;
  --grey-color: #F4F4F4;
  --light-grey-color: #EDEDED;


  --disabled-color: #97a3b4;
  --light-color: #d3e1f5;
  --black-color: rgba(0, 0, 0, 0.85);
  --white-color: #ffff;
  --light-blue-color: #d3e1f5;
  --black: rgba(0, 0, 0, 0.85);

  //loaders
  // in color
  --loader-start-color: #07192a;
  --loader-middle-color: #10394c;
  --loader-end-color: #106281;

  // light
  --loader-start-light: rgba(255, 255, 255, 0.7);
  --loader-middle-light: white;
  --loader-end-light: rgba(255, 255, 255, 0.4);

  // dark
  --loader-start-dark: rgba(0, 0, 0, 0.5);
  --loader-middle-dark: black;
  --loader-end-dark: rgba(0, 0, 0, 0.1);

  //transition
  --transition: 0.3s ease-in;

  //text
  --light-text-color: #6d6e70; //cancel text
  --light-text-hover-color: #5a6268;
  --text-color: #7C7C7C; //text color
  --dark-text-color: #141B2C;

  // error
  --error-color: #E50019;
  --error-hover-color: #E50019;
  --error-disabled-color: #E50019;

  //button
  --button-border-radius: 4px;
  //

  // modals
  --modal-background-color: white;
  --modal-borders-color: #d3e1f5;
  --modal-borders: 0.5px solid #e5e5e5;
  //

  // fields
  --field-disabled-color: #f2f2f2;
  --placeholder-color: #97a3b4;
  --field-border-color: #bccde5;
  --min-field-height: 45px;
  --field-border-radius: 6px;
  --field-font-size: 14px;
  --label-font-size: 12px;
}
