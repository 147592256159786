.container {
  background: #D3D3D33E;
  padding: 15px 15px;
  border-radius: 6px;

  .actions {
    color: var(--text-color);

    .links {
      font-size: 12px;
      padding-top: 3px;

      &:hover {
        color: var(--secondary-color);
        text-decoration: underline;
      }
    }
  }

}