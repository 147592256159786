@import "src/assets/style/variables";

.editBtn, .editBtnSmallScreen {
	margin-top: 27px;
	svg {
		font-size: 24px;
		cursor: pointer;
		color: var(--secondary-color);
	}
}

.editBtnSmallScreen {
	display: none;
}

@media (max-width: $md) {
	.editBtn {
		display: none;
	}
}

@media (max-width: $md) {
	.editBtnSmallScreen {
		display: flex;
	}
}

@media (max-width: $md) {
	.formFields {
		max-width: 100%;
	}
	.formFieldsWidth {
		width: 100%;
	}
}

@media (min-width: $md) {
	.formFieldsWidth {
		max-width: 244px !important;
	}
}
