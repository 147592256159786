.checkbox {
  display: inline-flex;
  align-items: center;
  font-family: 'AvenirNext-Regular', sans-serif;
  color: var(--text-color);
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  background-color: transparent;
  background-clip: padding-box;
  padding: 2px;
  margin: 0;

  span {
    padding-left: 10px;
  }

  input {
    width: 15px;
    height: 15px;

    &:hover {
      cursor: pointer;
    }
  }
}

.disabled {
  &:hover {
    pointer-events: none;
  }
}
