.suffix {
	display: inline-block;
	position: relative;
	width: 100%;
}

.suffix::after {
	position: absolute;
	top: 40px;
	right: 1em;
	transition: all .2s ease-in-out;
	content: 'ha';
	color: rgba($color: #000000, $alpha: 0.3);
}

.suffix:hover::after,
.suffix:focus-within::after {
	right: 1.2em;
}

.editBtn {
	margin-top: 27px;
	svg {
		font-size: 24px;
		cursor: pointer;
		color: var(--secondary-color);
	}
}

.circle {
	border: 1px solid var(--secondary-color);
	background-color: var(--secondary-color);
	height: 8px;
	border-radius: 50%;
	width: 8px;
	margin-left: 7px;
}