.preloader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
}

.loader {
  display: block;
  position: relative;
  border-radius: 50%;
  border-style: solid;
  border-color: transparent;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  &:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    border-style: solid;
    border-color: transparent;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }

  &:after {
    content: '';
    position: absolute;
    border-radius: 50%;
    border-style: solid;
    border-color: transparent;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

//sizes
.xl {
  width: 25vw;
  height: 25vw;

  &:before {
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
  }

  &:after {
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
  }

  &,
  &:before,
  &:after {
    border-width: 4px;
  }
}

.lg {
  width: 100px;
  height: 100px;

  &:before {
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
  }

  &:after {
    top: 11px;
    left: 11px;
    right: 11px;
    bottom: 11px;
  }

  &,
  &:before,
  &:after {
    border-width: 3px;
  }
}

.sm {
  width: 35px;
  height: 35px;

  &:before {
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
  }

  &:after {
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
  }

  &,
  &:before,
  &:after {
    border-width: 1.5px;
  }
}

.xs {
  width: 15px;
  height: 15px;

  &:before {
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
  }

  &:after {
    top: 2.5px;
    left: 2.5px;
    right: 2.5px;
    bottom: 2.5px;
  }

  &,
  &:before,
  &:after {
    border-width: 0.5px;
  }
}

//colors
.color {
  border-top-color: var(--secondary-color);
  &:before {
    border-top-color: var(--blue-primary-color);
  }
  &:after {
    border-top-color: var(--blue-hover-color);
  }
}

.light {
  border-top-color: var(--loader-start-light);
  &:before {
    border-top-color: var(--loader-middle-light);
  }
  &:after {
    border-top-color: var(--loader-end-light);
  }
}
.dark {
  border-top-color: var(--loader-start-dark);
  &:before {
    border-top-color: var(--loader-middle-dark);
  }
  &:after {
    border-top-color: var(--loader-end-dark);
  }
}
