.item {
	color: var(--text-color);
}

.is-link {
	transition: 0.3s ease-in-out;
	&:hover {
		cursor: pointer;
		text-decoration: underline;
		color: var(--text-color);
	}
}

.is-text:hover{
	cursor: text;
}

@media (max-width: 605px) {
	.item {
		font-size: 14px !important;
	}

	.is-link {
		font-size: 14px !important;
	}
}