.icon{
	padding-left: 20px;
	svg{
		width: 17px;
		height: 17px;
	}
	
	&:hover{
		cursor: pointer;
	}
}