.avatar-user {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  min-width: 2rem;
  border-radius: 6px;
  background-color: #ccc;
  color: #0b34a5;
}

.avatar-group {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  min-width: 2rem;
  border-radius: 6px;
  background-color: #0b34a5;

  img {
    height: 60%;
  }
}

.avatar-pic {
  img {
    height: 100%;
    width: 100%;
    border-radius: 6px;
  }
}
