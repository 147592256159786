.navBar {
  position: relative;

  &__logo {
    cursor: pointer;
  }

  &__header {
    padding: 12px 45px;
    border-bottom: 1px solid var(--light-grey-color);

    &--actions {
      cursor: pointer;
      margin-right: 17px;
      width: 38px;
      height: 38px;
    }

    &--actions:last-child {
      margin-right: 0;
    }
  }

  &__title {
    padding: 2px 45px;
    background-color: var(--primary-color);

    &--main {
      font-size: 22px;
    }

    &--subtitle {
      color: var(--text-color);
      font-family: AvenirNext, AvenirNext-Demi, sans-serif;
    }
  }

  @media (max-width: 768px) {
    &__header {
      padding: 12px 20px;

      &--actions {
        margin-right: 8px;
      }
    }

    &__title {
      padding: 2px 20px;
    }
  }
}

.navLinks {
  font-size: 15px;
  margin-right: 16px;
  padding: 8px 14px;
  background-color: transparent;
}

.navLinks.active {
  color: var(--dark-text-color);
  background-color: var(--primary-color);
  border-radius: 5px;
}

.badge {
  font-size: 15px !important;

  &:hover {
    text-decoration: underline;
  }
}